import React from "react";
import Button from "react-bootstrap/Button";
import { BsArrowRepeat } from "react-icons/bs";

export default function AppUpdateButton() {
  const forceReloadAppHandler = (e) => {
    // solution from https://stackoverflow.com/a/46982400
    navigator.serviceWorker.getRegistration().then(function (reg) {
      if (reg) {
        reg.unregister().then(function () {
          window.location.reload(true);
        });
      } else {
        window.location.reload(true);
      }
    });
    alert("App has been reloaded. Please restart the app now.");
  };

  return (
    <>
      <h3>Your version: v2.7.7</h3>
      <div>
        <Button onClick={forceReloadAppHandler}>
          <BsArrowRepeat /> Force Reload App
        </Button>
        <br />
        <span>
          The app stores its frontend data locally to reduce loading time and
          web traffic. Therefore the frontend data must be manually reloaded
          from time to time, to make sure that your app is always up to date.
          Use this button for this purpose.
        </span>
      </div>
    </>
  );
}
